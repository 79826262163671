import React from "react";

import "../../styles/common-tittle.css";
const SpecialHeading = () => {
	return (
		<>
			<h2 className="speacial-heading">معرض الأعمال</h2>
			<p>السيف للمعمار</p>
		</>
	);
};

export default SpecialHeading;
